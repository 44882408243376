import React from "react"
import styled from "styled-components"
import BodyCopy from "../bodyCopy"
import Heading from "../heading"
import BodyImage from "../bodyImage"
import Link from "../../utilities/gatsbyLink"
import Img from "gatsby-image"
import ReactPlayer from "react-player"

const SectionInfoRecap = (props) => {
  const {
    sectionHeading,
    sectionBody,
    videoUrl,
    showVideo,
    image1,
    imageAlt1,
    cta1Text,
    cta2Text,
    cta1Link,
    cta2Link,
    imageOrder = 1,
    textOrder = 2,
    textOffset = 0,
    darkBackground,
  } = props
  return (
    <StyledSection darkBackground={darkBackground}>
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className={`small-12 medium-6 cell medium-order-${imageOrder}`}>
            {videoUrl && videoUrl.length > 0 && showVideo ? (
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  controls={false}
                  playIcon={<div />}
                />
              </div>
            ) : (
              <div>{image1 && <Img fluid={image1} alt={imageAlt1} />}</div>
            )}
          </div>
          <div
            className={`small-12 medium-6 cell medium-order-${textOrder} medium-offset-${textOffset}`}
          >
            <SectionHeadingContainer>
              <StyledSectionHeading>{sectionHeading}</StyledSectionHeading>
              <StyledSectionBody
                dangerouslySetInnerHTML={{ __html: props.sectionBody }}
              />
            </SectionHeadingContainer>
            <StyledButtonGroup>
              {cta1Link && cta1Link && cta1Link.length > 0 && (
                <Link className="button large" to={cta1Link}>
                  {cta1Text}
                </Link>
              )}
              {cta2Link && cta2Link && cta2Link.length > 0 && (
                <Link className="button large" to={cta2Link}>
                  {cta2Text}
                </Link>
              )}
            </StyledButtonGroup>
          </div>
        </div>
      </div>
    </StyledSection>
  )
}
export default SectionInfoRecap

const StyledSectionHeading = styled.h3`
  font-weight: 700;
`
const StyledSectionBody = styled.p`
  font-weight: 400;
  font-size: 20px;
`
const StyledSection = styled.section`
  padding: 100px 0;
  ${(props) => {
    console.log("props", props)
    if (props.darkBackground === true) {
      return `background: #f9fafc;
;`
    } else {
      return `background: white;`
    }
  }}
`
const SectionHeadingContainer = styled.div.attrs({
  className: "small-12 cell",
})`
   {
    margin-bottom: 80px;
  }
`
const StyledButtonGroup = styled.div.attrs({
  className: "button-group",
})`
  a:nth-of-type(2) {
    margin-left: 20px;
  }
`
