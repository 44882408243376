import PropTypes from "prop-types"
import React from "react"

const Heading = ({ heading, tag: Tag, id, weight, fontSize }) => (
  <Tag
    id={id}
    dangerouslySetInnerHTML={{ __html: heading }}
    style={{ fontWeight: weight, fontSize: fontSize }}
  ></Tag>
)
Heading.propTypes = {
  id: PropTypes.string,
}
Heading.propTypes = {
  body: PropTypes.string,
}
Heading.propTypes = {
  tag: PropTypes.string,
}
Heading.propTypes = {
  weight: PropTypes.oneOf(["300", "400", "700"]),
}
Heading.propTypes = {
  fontSize: PropTypes.number,
}
Heading.propTypes = {
  default: "h2",
}

export default Heading
