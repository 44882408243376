import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SectionHeroPage from "../../components/sections/heroPage"
import HeadingRecap from "../../images/svg/inline-svg/heading_recap.svg"
import HeadingRecapMobile from "../../images/svg/inline-svg/heading_recap_mobile.svg"
import SectionCurrentSponsors from "../../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrsMobile from "../../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"
import Heading from "../../components/heading"
import Link from "../../utilities/gatsbyLink"
import SectionWinnersRecap from "../../components/sections/sectionWinnersRecap"
import SectionInfoRecap from "../../components/sections/sectionInfoRecap"
import ReactPlayer from "react-player"
import styled from "styled-components"
const RecapPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              bizzaboEventId
              year
              bizzaboEventId
            }
          }
          headerImageMobile: file(
            relativePath: { eq: "headers/header_recap_mobile.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 624) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerRecap: file(relativePath: { eq: "headers/header_recap.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          dummyImage: file(relativePath: { eq: "dummy/dummy-600X342.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          innovatorOfTheYear: file(
            relativePath: { eq: "awards/202403_Ascend_Website_Recap_IOTY.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          practiceOfTheYear: file(
            relativePath: { eq: "awards/202403_Ascend_Website_Recap_POTY.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ceuReacp: file(
            relativePath: { eq: "202403_Ascend_Website_Recap_CEU.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sessionsRecap: file(
            relativePath: { eq: "202403_Ascend_Website_Recap_Sessions.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerRecap = data.headerRecap.childImageSharp.fluid
        const headerImageMobile = data.headerImageMobile.childImageSharp.fluid
        const innovatorOfTheYear = data.innovatorOfTheYear.childImageSharp.fluid
        const practiceOfTheYear = data.practiceOfTheYear.childImageSharp.fluid
        const ceuReacp = data.ceuReacp.childImageSharp.fluid
        const sessionsRecap = data.sessionsRecap.childImageSharp.fluid
        const dummyImage = data.dummyImage.childImageSharp.fluid
        const year = data.site.siteMetadata.year

        return (
          <Layout location={location}>
            <Seo
              title={`Ascend ${year} Recap`}
              location={`https://ascendevent.com${location.pathname}`}
              description="Get the full rundown of this year's Ascend Physical Therapists and Business conference"
            />
            <SectionHeroPage
              eyebrow=""
              heading=""
              showHeadingForScreenReaderOnly={true}
              subheading=""
              image={headerRecap}
              mediaMediumOffset={0}
              textWidth={4}
              sectionHeading={<HeadingRecap />}
              mobileImage={headerImageMobile}
              ctaText=""
              ctaLink=""
              anchorLinkId="#register-for-ascend"
              mobileSectionHeading={<HeadingRecapMobile />}
              body="<p>Whether it was the world-class speakers, thought-provoking sessions, or next-level networking with peers from across the country, Ascend 2024 had something for everyone.</p><p>Check out the highlight reel, and then tell us what you thought about it all in our brief survey.</p>"
            />

            <section>
              <div className="grid-container">
                <div className="grid-x grid-padding-x align-center">
                  <div className="small-12 medium-8 cell text-center">
                    <PlayerWrapper className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        url="https://share.vidyard.com/watch/WpVUGRnwJnyAUDsfSkERNY"
                        width="100%"
                        height="100%"
                        controls={false}
                      />
                    </PlayerWrapper>
                  </div>
                  <div className="small-12 cell text-center">
                    <Heading
                      tag="h3"
                      heading="Share your thoughts."
                      weight="700"
                    />
                    <Heading
                      tag="p"
                      heading="Take the attendee survey to let us know what you thought of Ascend 2024."
                      fontSize={20}
                    />
                    <Link
                      to={`https://docs.google.com/forms/d/e/1FAIpQLSf5ViZoCH5CbReytHFP4Q-vrNyOXBFJFUnJM7BiQfntrYod_g/viewform?usp=sf_link`}
                      className="button large"
                    >
                      Take the Survey
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <SectionInfoRecap
              sectionHeading="CEUs are available for Ascend 2024."
              sectionBody="Be sure to fill out your evaluations before November 30, 2024."
              image1={ceuReacp}
              imageAlt1="students getting CEU credits from attending Ascend"
              cta1Text="Recieve CEU Credit"
              cta1Link="https://21853389.fs1.hubspotusercontent-na1.net/hubfs/21853389/2024/ascend/Ascend2024_CE-Information.pdf"
              darkBackground={false}
              textOrder={1}
              imageOrder={2}
              textOffset={0}
            />
            <SectionInfoRecap
              sectionHeading="Find all the Ascend Session slides here."
              sectionBody="Check out the presentation decks from your favorite panels and speakers."
              image1={sessionsRecap}
              imageAlt1="alt 1"
              cta1Text="View Session Slides"
              cta1Link="https://21853389.fs1.hubspotusercontent-na1.net/hubfs/21853389/2024/ascend/2024%20Ascend%20Presentations.zip"
              darkBackground={true}
              textOrder={2}
              imageOrder={1}
              textOffset={0}
            />

            <SectionWinnersRecap
              sectionHeading="Ascend Award Winners"
              sectionSubheading="Congratulations to the 2024 Ascend award winners!"
              image1={practiceOfTheYear}
              image2={innovatorOfTheYear}
              imageAlt1="HARTZ PT is the Innovator of the Year for WebPT"
              imageAlt2="MovementX is the Practice of the Year for WebPT"
              link1="https://www.hartzpt.com/"
              link2="https://movement-x.com/"
            />

            <SectionCurrentSponsors
              sectionHeading="Thank You to Our 2024 Sponsors"
              sectionHeadingMobile={<HeadingPastSpnsrsMobile />}
            />
            <SectionInfoRecap
              sectionHeading="Mapping the future of rehab therapy—together."
              sectionBody="<p>The lineup of the eleventh year of Ascend was stacked with sessions focused on uniting the industry to look to the future and move our profession forward. Committed to this end, WebPT is leading the way in shifting the outpatient rehab landscape with the first-ever Practice Experience Management (PXM) platform. Paired with its vast library of educational resources and cutting-edge AI solutions, WebPT is designed to act as an ally for therapists to tackle the challenges of today and transform patient care of the future.</p><p>Let’s keep the conversation going. Request a consultation to see what it means to partner with WebPT.</p>"
              image1={dummyImage}
              imageAlt1="alt 1"
              cta1Text="Check Out PXM"
              cta1Link="https://get.webpt.com/pxm-rmi?leadsource=Event&lead_source_detail=Ascend%20&utm_source=Hubspot&utm_medium=Email&utm_content=Ascend_webpage_PXM_CTA&utm_term=&product_interest=&customer_status=&sfdc_campaign=CORPORATE-EVENTS-ASCEND-Marketing-09-2024&sfdc_campaign_id=7014V0000029wIIQAY&utm_campaign=CORPORATE-EVENTS-ASCEND-Marketing-09-2024"
              darkBackground={true}
              textOrder={2}
              imageOrder={1}
              textOffset={0}
              videoUrl="https://play.vidyard.com/HxU4jc2iyfqRq7jtD5rQ8s"
              showVideo={true}
            />
          </Layout>
        )
      }}
    />
  )
}

export default RecapPage

const PlayerWrapper = styled.div.attrs({ className: "player-wrapper" })`
  margin-bottom: 30px;
`
