import React from "react"
import styled from "styled-components"
import BodyCopy from "../bodyCopy"
import Heading from "../heading"
import BodyImage from "../bodyImage"
import Link from "../../utilities/gatsbyLink"
import Img from "gatsby-image"

const SectionWinnersRecap = ({
  sectionHeading,
  sectionSubheading,
  image1,
  image2,
  imageAlt1,
  imageAlt2,
  link1,
  link2,
}) => (
  <StyledSection>
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <SectionHeadingContainer>
          <StyledSectionHeading>{sectionHeading}</StyledSectionHeading>
          <StyledSectionSubheading>{sectionSubheading}</StyledSectionSubheading>
        </SectionHeadingContainer>
        <div className="small-12 medium-6 cell">
          <Link to={link1}>
            <Img fluid={image1} alt={imageAlt1} />
          </Link>
          <AwardTitle>
            <Heading tag="p" heading="Practice of the Year" />
          </AwardTitle>
        </div>
        <div className="small-12 medium-6 cell">
          <Link to={link2}>
            <Img fluid={image2} alt={imageAlt2} />
          </Link>
          <AwardTitle>
            <Heading tag="p" heading="Innovator of the Year" />
          </AwardTitle>
        </div>
      </div>
    </div>
  </StyledSection>
)

export default SectionWinnersRecap

const AwardTitle = styled.div`
  * {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 40px;
    text-align: center;
  }
`
const StyledSectionHeading = styled.h3`
  font-weight: 700;
  text-transform: uppercase;
`
const StyledSectionSubheading = styled.p`
  font-weight: 400;
  font-size: 20px;
`
const StyledSection = styled.section``
const SectionHeadingContainer = styled.div.attrs({
  className: "small-12 cell text-center",
})`
   {
    margin-bottom: 80px;
  }
`
